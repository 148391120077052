import {patchState, signalStore, withHooks, withMethods, withState} from "@ngrx/signals";
import {tapResponse} from "@ngrx/operators";
import {inject} from "@angular/core";
import {SocialAuthService, SocialUser} from "@abacritt/angularx-social-login";
import {finalize, firstValueFrom, lastValueFrom, of, switchMap} from "rxjs";
import {UserService} from "../../user/user.service";
import {AuthData} from "../../user/user";
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationService} from "../notification/notification.service";
import {displayName} from "../../user/pipes/display-name.pipe";
import {UserLogin} from "./login/types/user-login";
import {FormGroup} from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";
import {UserRegister} from "./register/types/user-register";
import {PasswordResetRequest} from "./password-reset-request/types/password-reset-request";
import {PasswordReset} from "./password-reset/types/password-reset";
import {UserAuthState, UserStateInterface} from "./user.store";

export interface FormError {
  field: string,
  code: string,
  error?: string,
  userMessage?: string
}

export interface AuthStateInterface {
  formErrors: FormError[];
  errorMessage: string | null
}

export const defaultAuthState = {
  formErrors: [],
  errorMessage: null
} as AuthStateInterface

export const AuthStore = signalStore({providedIn: 'root'},
  withState<AuthStateInterface>(defaultAuthState),
  withMethods((
    store,
    userAuthState = inject(UserAuthState),
    socialAuthService = inject(SocialAuthService),
    userService = inject(UserService),
    // jwtService = inject(JwtService),
    router = inject(Router),
    routeState = inject(ActivatedRoute),
    notificationService = inject(NotificationService)
  ) => ({
    async passwordChange(form: FormGroup, id: string, data: any) {
      form.disable();

      return await firstValueFrom(userService.passwordChange(id, data).pipe(
        tapResponse(({
          error: (error: HttpErrorResponse) => {
            if (error.status >= 400 && error.status < 500) {
              let formErrors: FormError[] = []
              error.error.errors.forEach((err: any) => {
                formErrors.push({
                  field: err.field,
                  code: err.code,
                  userMessage: err.message
                })
              })
              patchState(store, {errorMessage: "Formularz zawiera błędy", formErrors: formErrors})
            } else {
              patchState(store, {errorMessage: "Wystąpił nieoczekiwany błąd."})
            }

            form.markAllAsTouched()
          },
          next: () => {
            notificationService.success(`Twoje hasło zostało zaaktualizowane.`)
            form.reset();
            form.markAsUntouched();
          },
          finalize: () => {
            form.enable();
          }
        }))
      ))
    },
    async passwordReset(form: FormGroup, id: string, token: string, data: PasswordReset) {
      form.disable();
      return await firstValueFrom(userService.passwordReset(id, token, data).pipe(
        tapResponse(({
            error: (error: HttpErrorResponse) => {
              let errorMessage = "Formularz zawiera błędy"

              if (error.status >= 400 && error.status < 500) {
                let formErrors: FormError[] = []

                if (error.status == 404) {
                  patchState(store, {errorMessage: "Nieprawidłowy token resetujący hasło."})
                  return
                }

                error.error.errors.forEach((err: any) => {
                  if (err.field === 'reset_token') {
                    errorMessage = err.message
                    return
                  }
                  formErrors.push({
                    field: err.field,
                    code: err.code,
                    userMessage: err.message
                  })
                })

                patchState(store, {errorMessage: errorMessage, formErrors: formErrors})
              } else {
                patchState(store, {errorMessage: "Wystąpił nieoczekiwany błąd."})
              }
            },
            next: () => {
              router.navigate(['/logowanie']).then(() => {
                notificationService.success(`Twoje hasło zostało zaaktualizowane - możesz się teraz zalogować.`)
              });
            },
            finalize: () => {
              form.markAllAsTouched()
              form.enable();
            }
          }
        ))
      ))
    },
    async passwordResetRequest(form: FormGroup, data: PasswordResetRequest) {
      form.disable();
      return await firstValueFrom(userService.passwordResetRequest(data).pipe(
        tapResponse(({
            error: (error: HttpErrorResponse) => {
              if (error.status >= 400 && error.status < 500) {
                patchState(store, {errorMessage: error.error.error_description})
              } else {
                patchState(store, {errorMessage: "Wystąpił nieoczekiwany błąd."})
              }
            },
            next: () => {
              router.navigate(['/']).then(() => {
                notificationService.success(`Pomyślnie wysłano prośbę o zresetowanie hasła. Jeżeli adres e-mail jest prawidłowy zostanie wysłany do Ciebie link do zmiany hasła.`)
              });
            },
            finalize: () => {
              form.markAllAsTouched()
              form.enable();
            }
          }
        ))
      ))
    },
    async updateProfile(form: FormGroup, id: string, data: any) {
      form.disable();

      return await firstValueFrom(userService.updateProfile(id, data).pipe(
        tapResponse(({
          error: (error: HttpErrorResponse) => {
            if (error.status >= 400 && error.status < 500) {
              let formErrors: FormError[] = []
              error.error.errors.forEach((err: any) => {
                formErrors.push({
                  field: err.field,
                  code: err.code,
                  userMessage: err.message
                })
              })
              patchState(store, {errorMessage: "Formularz zawiera błędy", formErrors: formErrors})
            } else {
              patchState(store, {errorMessage: "Wystąpił nieoczekiwany błąd."})
            }
          },
          next: () => {
            this.getIdentity().then(() => {
              notificationService.success(`Pomyślnie zaaktualizowano profil użytkownika.`)
            })
          },
          finalize: () => {
            form.markAllAsTouched()
            form.enable();
          }
        }))
      ))
    },
    async register(form: FormGroup, data: UserRegister) {
      form.disable();

      return await firstValueFrom(userService.register(data).pipe(
        tapResponse(({
          error: (error: HttpErrorResponse) => {
            if (error.status >= 400 && error.status < 500) {
              let formErrors: FormError[] = []
              error.error.errors.forEach((err: any) => {
                formErrors.push({
                  field: err.field,
                  code: err.code,
                  userMessage: err.message
                })
              })
              patchState(store, {errorMessage: "Formularz zawiera błędy", formErrors: formErrors})
            } else {
              patchState(store, {errorMessage: "Wystąpił nieoczekiwany błąd."})
            }
          },
          next: () => {
            notificationService.success(`Pomyślnie zarejestrowano konto. Zostaniesz teraz zalogowany / zalogowana na swoje konto.`)
          },
          finalize: () => {
            form.markAllAsTouched()
            form.enable();
          }
        }))
      ))
    },
    async login(credentials: UserLogin, form?: FormGroup) {
      form?.disable();

      return await firstValueFrom(userService.login(credentials).pipe(
        // tap((response: AccessTokenResponse) => {
        //   jwtService.saveToken(response.access_token)
        // }),
        switchMap(() => userService.getIdentity()),
        tapResponse(({
          error: (error: HttpErrorResponse) => {
            if (error.status >= 400 && error.status < 500) {
              patchState(store, {
                errorMessage: "Formularz zawiera błędy", formErrors: [
                  {field: 'password', code: 'invalid', userMessage: 'Nieprawidłowa nazwa użytkownika lub hasło.'}
                ]
              })

              notificationService.error('Nieprawidłowa nazwa użytkownika lub hasło.')
            } else {
              patchState(store, {errorMessage: "Wystąpił nieoczekiwany błąd."})
              notificationService.error('Wystąpił nieoczekiwany błąd.')
            }
          },
          next: (response: AuthData) => {
            if (response && response.authenticated) {
              const userState: UserStateInterface = {
                isAuthenticated: response.authenticated,
                user: response.user
              }

              userAuthState.setAuthenticated(userState)

              const redirectUrl = routeState.root.snapshot.queryParams['redirectURL'] || '/';

              router.navigate([router.parseUrl(redirectUrl)]).then(() => {
                notificationService.success(`Pomyślnie zalogowano. Witaj ${displayName(response.user)}!`)
              })
            }
          },
          finalize: () => {
            form?.markAllAsTouched()
            form?.enable();
          }
        })),
      ))
    },
    logout() {
      // jwtService.destroyToken()
      return firstValueFrom(userService.logout().pipe(
        switchMap(() => socialAuthService.signOut()),
        finalize(() => {
          patchState(store, defaultAuthState)
          userAuthState.logout()
          router.navigate(['/']).then(() => {
            notificationService.info(`Pomyślnie wylogowano.`)
          })
        })
      ))
    },
    getIdentity: async () => {
      return await lastValueFrom(userService.getIdentity().pipe(
        tapResponse(({
          next: (response: AuthData) => {
            userAuthState.setAuthenticated({isAuthenticated: response.authenticated, user: response.user})
          },
          error: (error: HttpErrorResponse) => {
            if (error.status >= 400 && error.status < 500) {
              patchState(store, {errorMessage: error.error.error_description})
            }
          }
        })),
      ))
    },
    checkLoginState: () => {
      socialAuthService.authState.pipe(
        switchMap((user: SocialUser) => {
          if (user == null) {
            return of(null)
          }

          return userService.loginWithProvider(user)
        }),
        // tap((response: any) => {
        //   if (response) {
        //     jwtService.saveToken(response.access_token)
        //   }
        // }),
        switchMap(() => userService.getIdentity()),
        tapResponse(({
          error: (error: HttpErrorResponse) => {
            if (error.status >= 400 && error.status < 500) {
              patchState(store, {errorMessage: error.error.error_description})
            }
          },
          next: (response: AuthData) => {
            if (response && response.authenticated) {
              userAuthState.setAuthenticated({isAuthenticated: response.authenticated, user: response.user})

              router.navigate(['/']).then(() => {
                notificationService.success(`Pomyślnie zalogowano. Witaj ${displayName(response.user)}!`)
              })
            }
          }
        }))
      ).subscribe()
    }
  })),
  withHooks({
    onInit({checkLoginState}) {
      checkLoginState()
      // setInterval(() => {
      //   getIdentity()
      // }, 60 * 1000)

      // getIdentity()
    }
  }),
);
